var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseIterator",
    _vm._g(
      _vm._b(
        {
          ref: "base_iterator",
          attrs: { perPage: _vm.perPage, filterProps: _vm.filterProps },
          scopedSlots: _vm._u([
            {
              key: "items",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("PrizeCard", {
                    attrs: { prize: item, clickable: _vm.clickable },
                    on: { "on-prize-click": _vm.prizeClick },
                    scopedSlots: _vm._u(
                      [
                        _vm.hasSlot("card-actions")
                          ? {
                              key: "actions",
                              fn: function(props) {
                                return [
                                  _vm._t("card-actions", null, null, props)
                                ]
                              }
                            }
                          : null,
                        _vm.hasSlot("card-absolute")
                          ? {
                              key: "absolute",
                              fn: function(props) {
                                return [
                                  _vm._t("card-absolute", null, null, props)
                                ]
                              }
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ])
        },
        "BaseIterator",
        _vm.attrs,
        false
      ),
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }