<template>
    <v-alert v-if="hasError" type="error">
        {{ $t('errors.generic') }}
    </v-alert>
    <div v-else>
        <div v-if="filterProps.showFilter">
            <v-row class="justify-end">
                <v-col cols="12" sm="12" md="4" class="py-0 my-0">
                    <div class="d-flex gap-x-4">
                        <v-text-field
                            outlined dense solo flat
                            v-model="filter"
                            :label="$t('components.iterators.BaseIterator.search_label')"
                            class="mb-4"
                            @keyup.enter="searchByFilter"
                        >
                            <template v-if="$vuetify.breakpoint.smAndDown" #append-outer>
                                <v-icon @click="searchByFilter" class="primary--text">mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </div>
                </v-col>
            </v-row>
        </div>

        <v-data-iterator
            :items="items"
            :loading="loading"
            item-key="id"
            disable-filtering
            disable-sort
            disable-pagination
            hide-default-footer
        >
            <template #header v-if="hasSlot('header')">
                <slot name="header"/>
            </template>

            <template #no-data>
                <v-row>
                    <v-col cols="12" class="d-flex justify-items-center py-6 secondary_font--text">
                        <span class="d-flex mx-auto">{{ $t('components.iterators.BaseIterator.no_data') }}</span>
                    </v-col>
                </v-row>
            </template>

            <template #loading>
                <v-row>
                    <v-col cols="12" class="d-flex justify-items-center py-6 secondary_font--text">
                        <span class="d-flex mx-auto">{{ $t('globals.loading') }}</span>
                    </v-col>
                </v-row>
            </template>

            <template #default="props">
                <v-row>
                    <v-col
                        v-for="(item, index) in props.items" :key="index"
                        v-bind="colProps"
                    >
                        <slot name="items" v-bind="{ item }"></slot>
                    </v-col>
                </v-row>
            </template>

            <template #footer>
                <SimplePaginator
                    :pagination.sync="pagination"
                    :items="items"
                    hidePerPage
                />
            </template>

        </v-data-iterator>
    </div>
</template>

<script>
import HasSlot         from '../../mixins/HasSlot'
import SimplePaginator from '../paginators/SimplePaginator'

export default {
    components: { SimplePaginator },
    props: {
        fetchFunction: {
            required: true,
            type: Function,
        },
        perPage: {
            type: Number,
            default: () => 6,
        },
        colProps: {
            type: Object,
            default: () => ({
                cols: 12,
            }),
        },
        filterProps: {
            type: Object,
            required: false,
            default: () => ({
                showFilter: false,
            }),
        },
    },
    mixins: [ HasSlot ],
    data: vm => ({
        loading : false,
        hasError: false,
        filter: '',

        pagination: {
            page    : 1,
            per_page: vm.perPage,
        },

        items: [],
        all_items: [],
    }),
    methods: {
        async preFetchItems(params = {}) {
            await this.fetchItems(params)
                .catch(this.preErrorHandler)
        },

        async fetchItems(params = {}) {
            this.loading = true
            this.items   = []

            this.items = await this.fetchFunction(this.pagination, params)
                .catch(this.preErrorHandler)
            this.loading = false
        },

        preErrorHandler(e) {
            // Indicar erro
            this.hasError = true

            // Este componente é compartilhado entre os repositórios e não pode depender do errorHandler
            this.$emit('error', e)
        },

        refresh() {
            this.items = []
            // Se já está na página 1, não vai para o watch, então disparo manualmente
            if (this.pagination.page == 1)
                this.preFetchItems()

            this.pagination.page = 1
            this.pagination.per_page = this.perPage
        },

        async searchByFilter() {
            await this.fetchItems({
                search_parameter: this.filter,
            }).catch(this.preErrorHandler)
        }
    },
    watch: {
        'pagination.page': {
            immediate: true,
            async handler() {
                await this.preFetchItems({
                    search_parameter: this.filter,
                })
            },
        },
    },
}
</script>