<template>
    <BaseIterator
        ref="base_iterator"
        v-bind="attrs"
        v-on="listeners"
        :perPage="perPage"
        :filterProps=filterProps
    >                   
        <template #items="{ item }">
            <PrizeCard :prize="item" @on-prize-click="prizeClick" :clickable="clickable">
                <template #actions="props" v-if="hasSlot('card-actions')">
                    <slot name="card-actions" v-bind="props"></slot>
                </template>
                <template #absolute="props" v-if="hasSlot('card-absolute')">
                    <slot name="card-absolute" v-bind="props"></slot>
                </template>
            </PrizeCard>
        </template>
    </BaseIterator>
</template>

<script>
import HasSlot      from '../../mixins/HasSlot'
import PrizeCard    from '../cards/PrizeCard'
import BaseIterator from './BaseIterator'

export default {
    name: 'SharedPrizeIterator',
    props: {
        zeroValueText: {
            type: String,
            required: false,
        },
        perPage: {
            type: Number,
            default: 6,
        },
        clickable: {
            type: Boolean,
            required: false,
            defualt: false,
        },
        filterProps: {
            type: Object,
            required: false,
            default: () => ({
                showFilter: false,
            }),
        },
    },
    components: { BaseIterator, PrizeCard },
    mixins: [ HasSlot ],
    inheritAttrs: false,
    methods: {
        async preFetchItems() {
            await this.$refs.base_iterator.preFetchItems()
        },
        prizeClick(prize) {
            this.$emit('on-prize-click', prize)
        }
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
}
</script>