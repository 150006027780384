var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasError
    ? _c("v-alert", { attrs: { type: "error" } }, [
        _vm._v(" " + _vm._s(_vm.$t("errors.generic")) + " ")
      ])
    : _c(
        "div",
        [
          _vm.filterProps.showFilter
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { staticClass: "justify-end" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 my-0",
                          attrs: { cols: "12", sm: "12", md: "4" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex gap-x-4" },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-4",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  solo: "",
                                  flat: "",
                                  label: _vm.$t(
                                    "components.iterators.BaseIterator.search_label"
                                  )
                                },
                                on: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.searchByFilter($event)
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.$vuetify.breakpoint.smAndDown
                                      ? {
                                          key: "append-outer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "primary--text",
                                                  on: {
                                                    click: _vm.searchByFilter
                                                  }
                                                },
                                                [_vm._v("mdi-magnify")]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      : null
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.filter,
                                  callback: function($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("v-data-iterator", {
            attrs: {
              items: _vm.items,
              loading: _vm.loading,
              "item-key": "id",
              "disable-filtering": "",
              "disable-sort": "",
              "disable-pagination": "",
              "hide-default-footer": ""
            },
            scopedSlots: _vm._u(
              [
                _vm.hasSlot("header")
                  ? {
                      key: "header",
                      fn: function() {
                        return [_vm._t("header")]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "no-data",
                  fn: function() {
                    return [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-items-center py-6 secondary_font--text",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", { staticClass: "d-flex mx-auto" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.iterators.BaseIterator.no_data"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "loading",
                  fn: function() {
                    return [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-items-center py-6 secondary_font--text",
                              attrs: { cols: "12" }
                            },
                            [
                              _c("span", { staticClass: "d-flex mx-auto" }, [
                                _vm._v(_vm._s(_vm.$t("globals.loading")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c(
                        "v-row",
                        _vm._l(props.items, function(item, index) {
                          return _c(
                            "v-col",
                            _vm._b(
                              { key: index },
                              "v-col",
                              _vm.colProps,
                              false
                            ),
                            [_vm._t("items", null, null, { item: item })],
                            2
                          )
                        }),
                        1
                      )
                    ]
                  }
                },
                {
                  key: "footer",
                  fn: function() {
                    return [
                      _c("SimplePaginator", {
                        attrs: {
                          pagination: _vm.pagination,
                          items: _vm.items,
                          hidePerPage: ""
                        },
                        on: {
                          "update:pagination": function($event) {
                            _vm.pagination = $event
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }